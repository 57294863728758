<template>
    <div>
        <section class="header-image">
            <div class="header-image-overlay"></div>
            <b-container>
                <b-row>
                    <b-col lg="7"><h1>{{ $t('message.services.corporate_networks_over_satellite.title') }}</h1>
                        <p class="lead">
                            {{ $t('message.services.corporate_networks_over_satellite.subtitle') }}
                        </p>
                    </b-col>
                    <b-col md="5">
                        <div class="header-breadcrumb">
                            <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{ $t('message.home.title') }}</router-link>
                            • <span class="text-muted">{{ $t('message.services.title') }}</span>
                            • <span class="text-muted">{{ $t('message.services.corporate_networks_over_satellite.title') }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="section bg-gray-alt text-center">
            <b-container>
                <p class="lead">
                    {{ $t('message.services.corporate_networks_over_satellite.paragraph_1') }}
                </p>

                <img class="margin-bottom img-fluid" src="../../assets/images/services/corporate-networks-vsat-mesh-backhaul/graphic.png" alt="Syntelix vsat" style="opacity: 0.3">

                <p class="lead">
                    {{ $t('message.services.corporate_networks_over_satellite.paragraph_2') }}
                </p>

                <p class="lead">
                    {{ $t('message.services.corporate_networks_over_satellite.paragraph_3') }}
                </p>
            </b-container>
        </section>

        <section class="section text-center">
            <b-container>
                <b-row>
                    <b-col sm="6">
                        <span class="icon-circle"><span class="li-clipboard text-muted"></span></span>
                        <h3 class="icon-circle-caption">{{ $t('message.features') }}</h3>
                        <hr class="hr-gradient">
                        <ul class="list-unstyled">
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.features.item_1') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.features.item_2') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.features.item_3') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.features.item_4') }}
                                <hr class="hr-gradient">
                            </li>
                        </ul>
                    </b-col>
                    <div class="col-sm-6">
                        <span class="icon-circle"><span class="li-heart text-muted"></span></span>
                        <h3 class="icon-circle-caption">{{ $t('message.benefits') }}</h3>
                        <hr class="hr-gradient">
                        <ul class="list-unstyled">
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.benefits.item_1') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.benefits.item_2') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.benefits.item_3') }}
                                <hr class="hr-gradient">
                            </li>
                            <li>
                                {{ $t('message.services.corporate_networks_over_satellite.benefits.item_4') }}
                                <hr class="hr-gradient">
                            </li>
                        </ul>
                    </div>
                </b-row>
            </b-container>
        </section>
        <QuickContact></QuickContact>
    </div>
</template>

<script>
    import QuickContact from "../../components/QuickContact";
    export default {
        name: "CorporateNetworksOverSatellite",
        components: {
            QuickContact
        },
        metaInfo () {
            return  {
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                title: this.$t('meta.services.corporate_networks_over_satellite.title'),
                link: [
                    { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                ],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.services.corporate_networks_over_satellite.description')
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.services.corporate_networks_over_satellite.title')
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.services.corporate_networks_over_satellite.description')
                    },
                    {
                        vmid: 'og:type',
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: 'https://www.syntelix.net' + this.$route.path
                    }
                ],
                script: [{
                    type: 'application/ld+json',
                    json: {
                        "@context": "http://schema.org",
                        "@type": "Service",
                        "provider": {
                            "@type": "Corporation",
                            "legalName": "Syntelix Avances Tecnológicos S.L.",
                            "brand": "Syntelix",
                            "name": "Syntelix",
                            "description": this.$t('meta.home.description'),
                            "url": "https://www.syntelix.net",
                            "email": "info@syntelix.net",
                            "faxNumber": "(+34) 986 064 336",
                            "logo": 'https://www.syntelix.net' + require('@/assets/images/logo.png'),
                            "telephone": "(+34) 911 938 062",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "PTL Valladares Calle C, Nave C4",
                                "addressLocality": "Vigo",
                                "addressCountry": "España",
                                "postalCode": "36314"
                            }
                        },
                        "name": this.$t('meta.services.corporate_networks_over_satellite.title'),
                        "description": this.$t('meta.services.corporate_networks_over_satellite.description')
                    }

                }]
            }
        }
    }
</script>

<style scoped>

</style>
